html {
  scroll-behavior: smooth;
}

.about-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.about-header,
.testimonial-header, 
.CSR-container-title,
.CSR-container-blog {
  color: lightgray;
  font-size: 4rem;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.about-header2 {
  color: lightyellow;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.it-styled {
  color: lightyellow;
  font-size: 3rem;
  font-weight: bold;
}

.CSR-container {
  padding-bottom: 100px;
  width: 100vw;
}

.LOR-header,
.LOR-link {
  color: lightgray;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.about-paragraph, .CSR-container-blog {
  color: lightgray;
  font-size: 2rem;
  margin-top: 20px;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-bottom: 50px;
}


.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
}

.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 60%; /* set the height to 60% */
  object-fit: contain;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto; /* set the height to 40% */
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.card-link {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.stack {
  margin-right: 10px;
  color: blue;
}

.stack:last-child {
  margin-bottom: 20px;
}

.stack-description {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.project-link {
  margin-right: 10px;
  margin-bottom: 10px;
}

.hosted-link,
.project-link {
  background-color: black;
  padding: 8px;
  color: white;
  margin-top: 10px;
}

.pdf-container {
  background-color: transparent;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10%;
}

.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.testimonial-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-card:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.testimonial-card:last-child {
  margin-bottom: 60px;
}

.testimonial-content {
  text-align: center;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0;
}

.testimonial-title {
  font-style: italic;
  margin-top: 0;
}

.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: 20px;
}

.LOR-link {
  list-style-type: none;
}

.responsive-image {
  width: 100%;
  height: 60%;
  object-fit: contain;
}

.old-home-container,
.recommendations-container,
.old-navbar-container,
.old-footer-container,
.old-jobseekers-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-site-container, .HTTPS-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-site-container img,
.HTTPS-container img,
.old-home-container img,
.recommendations-container img,
.old-navbar-container img,
.old-footer-container img,
.old-jobseekers-container img {
  width: 90%;
  margin-bottom: 20px;
}

.anchor-new-site {
  display: flex;
  justify-content: center;
}

.new-site-link {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #009688;
  border-radius: 5px;
  color: #009688;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.new-site-link:hover {
  background-color: #009688;
  color: #fff;
}

.credly-badge,
.microsoft-badge,
.six-sigma-badge,
.comptia-badge,
.veeam-badge {
  width: 200px;
  height: auto;
  margin-right: 5px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .about-header {
    font-size: 3rem;
  }

  .about-header2 {
    font-size: 2rem;
  }

  .about-paragraph {
    font-size: 1.5rem;
    width: 80%;
    margin: auto;
  }

  .pdf-container {
    padding-left: 0;
    padding-right: 0;
  }

  .CSR-container-blog,
  .new-site-container-images {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    width: 80%;
    font-size: 1rem;
  }
  .credly-badge,
.microsoft-badge,
.six-sigma-badge,
.comptia-badge,
.veeam-badge {
  width: 125px;
  height: auto;
  margin-right: 5px;
  margin-bottom: 10px;
}
}

@media only screen and (max-width: 480px) {
  .about-header {
    font-size: 2rem;
  }

  .about-header2 {
    font-size: 1.5rem;
  }

  .about-container {
    padding: 5px;
  }

  .about-paragraph {
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
  }

  /* .about-paragraph {
      font-size: 1rem;
      width: 100%;
    } */

  .testimonial-card {
    width: 100vw;
  }
  .CSR-container{
    width: 100%;
  }

  .CSR-container-blog,
  .new-site-container-images {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    width: 80%;
    font-size: 1rem;
  }
  .credly-badge,
  .microsoft-badge,
  .six-sigma-badge,
  .comptia-badge,
  .veeam-badge {
    width: 100px;
    height: auto;
    margin-right: 5px;
    margin-bottom: 10px;
  }
}
