.icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
  }
  
  .icon-link {
    margin: 40px;
    color: lightgray;
  }