html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: url('./images/black-background.avif') no-repeat center center fixed;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-color: black;
  }
  
  .job, .dot, .t-stack, .realName, .stackName, .footer-bottom {
    color: lightgray;
  }
  
  .realName {
    font-size: 4rem;
    font-weight: bold;
  }
  
  .main {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-right: 20%; */
    margin-left: auto;
    /* padding-left: 10%; */
  }
  
  .right-section {
    /* flex: 0 0 250px; */
    margin-right: 13%;
    margin-top: 20px;
  }
  
  .middle-section {
    margin-top: 20px;
    text-align: center;
    color: lightgray;
    font-style: italic;
    font-size: 1.2rem;
  }
  
  .quote {
    color: lightgray;
    font-style: italic;
    font-size: 1.2rem;
  }
  
  .footer-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: transparent;
  }
  
  /* styles for screens larger than 768px */
  @media screen and (min-width: 768px) {
    .main {
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .left-section {
      flex: 1;
      margin-right: 20px;
      margin-left: auto; /* added */
    }
    .right-section {
      flex: 0 0 250px;
    }
  }

  .nav-tabs-lg {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }

  @media screen and (min-width: 1200px) {
    body {
      font-size: 24px;
    }
    .realName {
      font-size: 6rem;
    }
    .tech-job {
      font-size: 2rem;
    }
    .stackName {
      font-size: 4rem;
    }
    .tech-stacks {
      font-size: 1.6rem;
    }
    .quote {
      font-size: 2rem;
    }
    img {
        width: 100%;
        max-width: 800px; /* set a maximum width to prevent the image from becoming too large */
        height: auto; /* maintain the aspect ratio of the image */
      }
  }

  /* Increase the font size for screens smaller than 768px: */
  @media screen and (max-width: 768px) {
    .realName {
      font-size: 3rem;
    }
    .tech-job {
      font-size: 1.5rem;
    }
    .stackName {
      font-size: 3rem;
    }
    .tech-stacks {
      font-size: 1.2rem;
    }
    .quote {
      font-size: 1.5rem;
    }
  }

  /* Reduce the margin, padding, layout and img for smaller screens: */
  @media screen and (max-width: 576px) {
    .left-section, .right-section {
      margin: 10px 0;
      padding: 0;
      text-align: center;
      margin-top: 10px;
      padding-bottom: 10px;
    }

    img.my-photo {
      width: 90%;
      max-width: 400px;
      height: auto;
    }

    .main {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
  }